import { PlaylistInfo } from "classes/PlaylistInfo";
import { SongEventsByDate } from "classes/SongEventsByDate";
import { SongInfo } from "classes/SongInfo";
import { PlaylistDto } from "dto/PlaylistDto.dto";
import { PlaylistSongDto } from "dto/PlaylistSongDto.dto";
import { SongEventsDto } from "dto/SongEvents.dto";


export default class Mapper {
    constructor() {}

    static mapPlaylistSongDtoToSongInfo(PlaylistSongDto: PlaylistSongDto): SongInfo {
        return {
            id: PlaylistSongDto.id,
            added_at: new Date(PlaylistSongDto.added_at),
            removed_at: PlaylistSongDto.removed_at?new Date(PlaylistSongDto.removed_at):null,
            id_song: PlaylistSongDto.song.id,
            title: PlaylistSongDto.song.title,
            artist: PlaylistSongDto.song.artist,
            album: PlaylistSongDto.song.album,
            duration: PlaylistSongDto.song.duration,
            url: PlaylistSongDto.song.url,
            image_url: PlaylistSongDto.song.image_url,
            is_local: PlaylistSongDto.song.is_local,
            is_selected: false
        } as SongInfo
    }

    static mapPlaylistDtoToPlaylistInfo(playlistDto: PlaylistDto): PlaylistInfo {
        return {
            id: playlistDto.id,
            nb_songs: playlistDto.nb_songs,
            songs: playlistDto.songs.map(Mapper.mapPlaylistSongDtoToSongInfo)
        } as PlaylistInfo
    }

    static mapSongEventsDtoToSongEventsByDateList(events: SongEventsDto): SongEventsByDate[] {const songEventsByDate:Array<SongEventsByDate> = [];
        let currentSongEventsByDate: SongEventsByDate = {
            date: new Date(events.events[0].date),
            events: []
        };

        for(let event of events.events){
            const currentDate: Date = new Date(event.date);            

            if(currentSongEventsByDate.date.getTime() !== currentDate.getTime()) {
                songEventsByDate.push(currentSongEventsByDate);
                currentSongEventsByDate = {
                  date: currentDate,
                  events: []
                }
            }

            currentSongEventsByDate.events.push(event);
        }
        
        if(currentSongEventsByDate.date.getTime() !== songEventsByDate[songEventsByDate.length -1]?.date.getTime()) {
          songEventsByDate.push(currentSongEventsByDate);
        }
        
        return songEventsByDate;
    }
}