export function formatDate(date: Date): string {
    const dateOptions: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    };

    return date.toLocaleDateString("fr-FR", dateOptions);
}

export function formatDateSimple(date: Date): string {
    const dateOptions: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric'
    };

    return date.toLocaleDateString("fr-FR", dateOptions);
}

export function checkIf2WeeksDifference(dt2: Date, dt1: Date): boolean {
    const dt2With2WeeksOffset = new Date(dt2.getTime() - 12096e5);

    return dt1.getTime() > dt2With2WeeksOffset.getTime();
}