
import { PlaylistsByYear } from 'classes/PlaylistsByYear';
import PlaylistListSection from 'components/playlist-list-section/PlaylistListSection';
import { useEffect, useState } from 'react';

const apiUrl: string = process.env.REACT_APP_API_URL!
const playlistId: string = process.env.REACT_APP_PLAYLIST_ID!

export default function Homepage() {
    const [playlistsByYears, setPlaylistsByYears] = useState<Array<PlaylistsByYear>>([]);

    useEffect(() => {
        fetch(`${apiUrl}/playlist/${playlistId}/summaries`)
            .then(response => response.json())
            .then((data: any) => {
                const playlistsByYears:Array<PlaylistsByYear> = [];
                let currentPlaylistsByYear:PlaylistsByYear = {
                    year: new Date(data[0].date).getFullYear(),
                    playlists: []
                };
                for(let i of data){
                    const currentYear = new Date(i.date).getFullYear();

                    if(currentPlaylistsByYear.year !== currentYear) {
                        playlistsByYears.push(currentPlaylistsByYear);
                        currentPlaylistsByYear = {
                            year: currentYear,
                            playlists: []
                        }
                    }
                    currentPlaylistsByYear.playlists.push(i);
                }
                
                if(currentPlaylistsByYear.year !== playlistsByYears[playlistsByYears.length -1]?.year) {
                    playlistsByYears.push(currentPlaylistsByYear);
                }
                
                setPlaylistsByYears(playlistsByYears)
            });
    }, [])

    return(
        <div className="list-section">
            {
                playlistsByYears.map((playlistsByYear) => <PlaylistListSection key={playlistsByYear.year} {...playlistsByYear}/>) 
            }
        </div>
    )
}