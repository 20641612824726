import Homepage from 'pages/homepage/Homepage';
import PlaylistAllSongs from 'pages/playlist-all-songs/PlaylistAllSongs';
import PlaylistDetail from 'pages/playlist-detail/PlaylistDetail';
import PlaylistEvents from 'pages/playlist-events/PlaylistEvents';
import { useState } from 'react';
import { Route, Routes } from 'react-router-dom';

export default function Navigator() {
	return (
		<Routes>
			<Route path="/" element={<Homepage />} />
			<Route path="playlist/date/:date" element={<PlaylistDetail />} />
			<Route path="playlist/full" element={<PlaylistAllSongs/>} />
			<Route path="playlist/events" element={<PlaylistEvents/>} />
		</Routes>
	);
}