import Mapper from "app/mapper";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { SongEventsByDate } from "classes/SongEventsByDate";
import { SongEventsDto } from "dto/SongEvents.dto";
import EventsByDateSection from "components/events-by-date-section/EventsByDateSection";

const apiUrl: string = process.env.REACT_APP_API_URL!
const playlistId: string = process.env.REACT_APP_PLAYLIST_ID!

export default function PlaylistEvents() {
    const [events, setEvents] = useState<SongEventsByDate[]>([]);
    const [skip, setSkip] = useState(1);

    useEffect(() => {
        fetch(`${apiUrl}/playlist/${playlistId}/events?limit=20`)
            .then(response => response.json())
            .then((data: SongEventsDto) => {
                setSkip(data.nextSkip);
                
                setEvents(Mapper.mapSongEventsDtoToSongEventsByDateList(data));
            })
    }, [])

    const fetchMoreData = () => {
        if(skip != 0) {
            fetch(`${apiUrl}/playlist/${playlistId}/events?limit=20&skip=${skip}`)
                .then(response => response.json())
                .then(data => {
                    setSkip(data.nextSkip);
                    setEvents([...events, ...Mapper.mapSongEventsDtoToSongEventsByDateList(data)]);
                })
        }
    };

    return (
        <InfiniteScroll
          dataLength={events.length}
          next={fetchMoreData}
          hasMore={skip != 0}
          loader={<h4>Loading...</h4>}
          endMessage={
            <p style={{ textAlign: 'center' }}>
              <b>Yay! You have seen it all</b>
            </p>
          }
        >
          <div className='events-container'>
              {events &&
                events.map((event: SongEventsByDate, index: number) => <EventsByDateSection key={index} {...event}/>)}
          </div>
        </InfiniteScroll>
    )
}