import { PlaylistsByYear } from "classes/PlaylistsByYear";
import { PlaylistCard } from "components/playlist-card/PlaylistCard";
import { PlaylistSummaryDto } from "dto/PlaylistSummaryDto.dto";

export default function PlaylistListSection(playlistList: PlaylistsByYear) {
    return(
        <div className="playlist-section">
            <h2 className="playlist-year-title">{playlistList.year}</h2>
            <hr/>
            <div className="playlist-list">
            {
                playlistList.playlists.map((playlist) => {
                    return {
                    date: new Date(playlist.date),
                    nb_songs: playlist.nb_songs,
                    id: playlist.id
                    } as PlaylistSummaryDto
                })
                .map((playlist: PlaylistSummaryDto, index: number) => {
                    return (<PlaylistCard key={index} {...playlist} />)
                })
            }
            </div>
        </div>
    )
}