import { Link, NavLink } from "react-router-dom"

export default function Header() {
    return(
        <header className="header">
          <Link to="/">
            <h1>Spotify Versionning</h1>
          </Link>
          <nav className="navbar">
            <NavLink to="/playlist/full">
              <p>Tous les sons</p>
            </NavLink>
            <NavLink to="/playlist/events">
              <p>Activités</p>
            </NavLink>
          </nav>
        </header>
    )
}