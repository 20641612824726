import localSongPicture from "assets/img/local-song.svg"
import recentlyAddedIcon from "assets/img/recent-stars.svg"
import { MouseEventHandler } from 'react';
import { SongInfo } from "classes/SongInfo";
import { checkIf2WeeksDifference, formatDate, formatDateSimple } from "app/utils";

export default function SongRow(props: { song: SongInfo, onClickElement: (id_song: number, shiftPressed: boolean) => void, currentDate: Date | null}) {
    const { song, onClickElement, currentDate } = props;

    const handleClick: MouseEventHandler<HTMLDivElement> = (e) => {
        onClickElement(song.id, e.shiftKey);
    };

    const isRecent: boolean = currentDate?checkIf2WeeksDifference(currentDate, song.added_at):false

    return (
        <div className={`song-row${song.is_selected?" song-row-selected":""}${song.removed_at?" song-deleted":""}`} role="button" tabIndex={0} onClick={handleClick}>
            {
                song.is_local
                    ?<div className="song-local-image"><img className="song-image" src={localSongPicture}/></div>
                    :<img className="song-image" src={song.image_url}/>
            }
            <div className="song-title-artist">
                <p className="song-title">{song.title.replaceAll("''", "'")}</p>
                <p className="song-artist">{song.artist?.replaceAll("''", "'")}</p>
            </div>
            <div className={`song-added-at${!song.removed_at?" full-width":""}`}>
                <p className="song-added-at-text song-date-base">{formatDate(new Date(song.added_at))}</p>
                <p className="song-added-at-text song-date-simple">{formatDateSimple(new Date(song.added_at))}</p>
                { isRecent && 
                    <div className="song-recently-added">
                        <img src={recentlyAddedIcon}/>
                        <p>Ajouté récemment</p>
                    </div>
                }
            </div>
            {
                song.removed_at &&
                <div className="song-removed-at">
                <p className="song-removed-at-text song-date-base">{formatDate(new Date(song.removed_at))}</p>
                <p className="song-removed-at-text song-date-simple">{formatDateSimple(new Date(song.removed_at))}</p>
            </div>
            }
        </div>
    )
}
