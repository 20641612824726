import { SongEventDto } from "dto/SongEvent.dto";
import localSongPicture from "assets/img/local-song.svg"


export function EventCard(event: SongEventDto) {

    return(
        <div className={`event-container event-${event.event_type}`}>
            <div className="event-info">
                <p className="event-added-or-removed">{(event.event_type == "add")?"Ajout d'un son":"Suppression d'un son"}</p>
                <p className="song-title">{event.song.title.replaceAll("''", "'")}</p>
                <p className="song-artist">{event.song.artist?.replaceAll("''", "'")}</p>
            </div>
            {
                event.song.is_local
                    ?<div className="song-local-image"><img className="song-image" src={localSongPicture}/></div>
                    :<img className="song-image" src={event.song.image_url}/>
            }
        </div> 
    )
}