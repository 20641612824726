import { PlaylistSummaryDto } from "dto/PlaylistSummaryDto.dto";
import playlistPicture from "assets/img/playlist-picture.png"
import { Link } from "react-router-dom";


export function PlaylistCard(playlist: PlaylistSummaryDto) {
    const dateOptions: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'long',
    };

    const dateLinkOptions: Intl.DateTimeFormatOptions = { 
        year: 'numeric', 
        month: '2-digit', 
        day: '2-digit' 
    };
    
    return(
        <Link to={`/playlist/date/${playlist.date.toLocaleDateString('en-CA', dateLinkOptions).replaceAll("/", "-")}`} className="playlist-card">
            <img className="playlist-picture" src={playlistPicture}/>
            <p className="playlist-date">{playlist.date.toLocaleDateString("fr-FR", dateOptions)}</p>
            <p className="playlist-nb-songs">{playlist.nb_songs} titres</p>
        </Link>
    )
}