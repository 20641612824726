import Homepage from 'pages/homepage/Homepage';
import './App.scss';
import Header from './components/header/Header';
import { BrowserRouter } from 'react-router-dom';
import Navigator from 'app/Navigator';

function App() {
  return (
    <div className="spotify-app">
      <BrowserRouter>
          <Header/>
		      <Navigator />
      </BrowserRouter>
    </div>
  );
}

export default App;
