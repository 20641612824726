import Mapper from "app/mapper"
import { formatDate } from "app/utils"
import playlistPicture from "assets/img/playlist-picture.png"
import { PlaylistInfo } from "classes/PlaylistInfo"
import SongListSection from "components/song-list-section/SongListSection"
import { PlaylistSongDto } from "dto/PlaylistSongDto.dto"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"

const apiUrl: string = process.env.REACT_APP_API_URL!
const playlistId: string = process.env.REACT_APP_PLAYLIST_ID!

export default function PlaylistDetail() {
    const [currentPlaylist, setCurrentPlaylist] = useState<PlaylistInfo>({id: "", nb_songs: 0, songs: []})
    const { date } = useParams();

    useEffect(() => {
        fetch(`${apiUrl}/playlist/${playlistId}/date/${date}`)
            .then(response => response.json())
            .then(data => {
                data.songs.sort((a: PlaylistSongDto, b: PlaylistSongDto)=> new Date(b.added_at).getTime() - new Date(a.added_at).getTime())
                return data
            })
            .then(Mapper.mapPlaylistDtoToPlaylistInfo)
            .then(setCurrentPlaylist)
    }, [])

    return (
        <>
            <section className="top-section">
                <div className="playlist-info">
                    <img className="playlist-picture" src={playlistPicture}/>
                    <div className="playlist-detail">
                        <h2 className="playlist-date">{formatDate(new Date(date!))}</h2>
                        <p className="playlist-nb-songs">{currentPlaylist.nb_songs} titres</p>
                    </div>
                </div>
            </section>

            <SongListSection {...{currentPlaylist, date: date!, setCurrentPlaylist, isFullPlaylist: false}} />
        </>
    )
}