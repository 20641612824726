import { formatDate } from "app/utils";
import { SongEventsByDate } from "classes/SongEventsByDate";
import { EventCard } from "components/event-card/EventCard";
import { SongEventDto } from "dto/SongEvent.dto";

export default function EventsByDateSection(eventsByDate: SongEventsByDate) {
    const formattedDate = formatDate(eventsByDate.date);

    return(
        <div className="events-by-datesection">
            <div className="events-date">
                <div className="separator"></div>
                <h2>{formattedDate}</h2>
                <div className="separator"></div>
            </div>
            <div className="events-list">
            {
                eventsByDate.events.map((event: SongEventDto, index: number) => {
                    return (<EventCard key={index} {...event} />)
                })
            }
            </div>
        </div>
    )
}