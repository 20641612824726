import { PlaylistInfo } from "classes/PlaylistInfo";
import { SongInfo } from "classes/SongInfo";
import SongRow from "components/song-row/SongRow";
import { useEffect, useRef } from "react";

export default function SongListSection(props: {currentPlaylist: PlaylistInfo, date: string | null, setCurrentPlaylist: React.Dispatch<React.SetStateAction<PlaylistInfo>>, isFullPlaylist: boolean}) {
    const { currentPlaylist, date, setCurrentPlaylist, isFullPlaylist } = props;
    const documentRef = useRef(document); 

    useEffect(() => {
        documentRef.current.addEventListener("keydown", handleKeyboard);

        return () => {
            documentRef.current.removeEventListener("keydown", handleKeyboard)
        }
        
    }, [currentPlaylist])

    function handleKeyboard(event: KeyboardEvent) {
        if (event.ctrlKey && event.key === 'c') {
            const songsToCopy = currentPlaylist.songs.filter(song => song.is_selected);
    
            let stringToCopy = "";

            songsToCopy.forEach(song => {
                if(song.is_local) {
                    const localSongId = song.id_song.split(":")[1]; 
                    stringToCopy += `https://open.spotify.com/local///${song.title.replaceAll(" ", "%20")}/${localSongId}\n`;
                } else {
                    stringToCopy += `https://open.spotify.com/track/${song.id_song}\n`;
                }
            })

            navigator.clipboard.writeText(stringToCopy);
        }
        
        if(event.key === "Escape") {
            currentPlaylist.songs.map(song => song.is_selected = false);
            setCurrentPlaylist({...currentPlaylist, songs: [...currentPlaylist.songs]});
        }
    }

    function handleClickOnRow(id_song: number, shiftPressed: boolean = false) {
        const previousSelectedSongIndex = currentPlaylist.songs.findIndex((song: SongInfo) => song.is_selected == true);

        const songIndex = currentPlaylist.songs.findIndex((song: SongInfo) => song.id == id_song);

        if(shiftPressed) {
            if(previousSelectedSongIndex == -1) {
                currentPlaylist.songs[songIndex].is_selected = true;
            } else {
                const firstIndex = (previousSelectedSongIndex < songIndex)?previousSelectedSongIndex:songIndex;
                const lastIndex = (previousSelectedSongIndex < songIndex)?songIndex:previousSelectedSongIndex;

                for(let i = firstIndex; i <= lastIndex; i++) {
                    currentPlaylist.songs[i].is_selected = true;
                }
            }
        } else {
            currentPlaylist.songs.map(song => song.is_selected = false);
    
            currentPlaylist.songs[songIndex].is_selected = true;
        }
            
        setCurrentPlaylist({...currentPlaylist, songs: [...currentPlaylist.songs]});
    }

    return (
        <section className="bottom-section">
            <div className="list-header">
                <p className="empty-space"></p>
                <p className="title-space">Titre</p>
                <p className="added-date-space">Date d'ajout</p>
                {isFullPlaylist && <p className="deleted-date-space">Date de suppression</p>}
            </div>
            <hr/>
            <ul className="list-body">
                {
                    currentPlaylist.songs.map(song => <SongRow key={song.id} {...{song: song, onClickElement: handleClickOnRow, currentDate: date ? new Date(date) : null}}/>)
                }
            </ul>
        </section>
    )
}